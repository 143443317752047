import { OnInit } from '@angular/core';
import { BaseRoutableComponent } from '../../shared/components/base-routable.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceManager } from '../../shared/services/service-manager';
import {
  AlignmentCountSummary,
  PipelineEntityType,
  DataSource,
  Notice,
  NoticeCountSummary,
  ProgramSchema, SourceSystem, CurriculumEntityType
} from '../../api/datacleanuptool-api.model';
import { ApplicationStateService } from '../../shared/services/application-state.service';
import { Observable } from 'rxjs';
import { SourceCountSummaryBySourceSystem } from '../../shared/entity-system/entities/curriculum-year';
import {labelForDataSource} from "../../api/extensions";


export abstract class ProgramsDashboardBase extends BaseRoutableComponent implements OnInit {

  ProgramSchema = ProgramSchema;
  SourceSystem = SourceSystem;

  unitsGroup = 'unitsGroup';
  titleAwardGroup = 'titleAwardGroup';

  private noticeCounts: NoticeCountSummary;
  sourceCounts$: Observable<SourceCountSummaryBySourceSystem>;
  private alignmentCounts: AlignmentCountSummary;

  constructor (
    activatedRoute: ActivatedRoute,
    private router: Router,
    serviceManager: ServiceManager,
    private appState: ApplicationStateService,
  ) {
    super(
      serviceManager,
      activatedRoute,
    );
  }
  get collegeImportLabel(): string | null { return labelForDataSource(this.curriculumYear.collegeDataSource); }

  ngOnInit() {
    this.curriculumYear.aggregateNoticeCounts(CurriculumEntityType.PROGRAM, {
      [this.titleAwardGroup]: [ProgramSchema.title, ProgramSchema.awardType],
      [this.unitsGroup]: [ProgramSchema.minUnits, ProgramSchema.maxUnits],
    }).then(result => this.noticeCounts = result);

    this.curriculumYear.aggregateAlignmentCounts(PipelineEntityType.PROGRAM)
      .then(result => this.alignmentCounts = result);

    this.sourceCounts$ = this.curriculumYear.aggregateSourceCounts$(PipelineEntityType.PROGRAM);
  }

  get curriculumYear() {
    return this.appState.curriculumYear;
  }

  noticeCount(fieldOrGroup: string, notice: Notice['@type']) {
    return this.noticeCounts && this.noticeCounts.fieldNoticeCounts[fieldOrGroup] && this.noticeCounts.fieldNoticeCounts[fieldOrGroup][notice];
  }

}




