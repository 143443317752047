import { ActivatedRoute } from '@angular/router';
import { BaseRoutableComponent } from './base-routable.component';
import { ServiceManager } from '../services/service-manager';
import { Observable } from 'rxjs';
import {
  NoticeTypeFilter,
  PaginatedFilteredCourseSearchResults,
  PaginatedFilteredProgramSearchResults
} from '../../api/datacleanuptool-api.model';
import { PaginationObject } from './pagination/pagination.component';
import { OnInit } from '@angular/core';


export abstract class BaseSearchComponent <TSearchResults extends PaginatedFilteredCourseSearchResults | PaginatedFilteredProgramSearchResults> extends BaseRoutableComponent implements OnInit {
  protected constructor(
    protected readonly serviceManager: ServiceManager,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(serviceManager, activatedRoute);
  }
  totalPages: number;
  page : number;
  searchResults$: Observable<TSearchResults>;
  private _searchTerm: string;

  ngOnInit() {
    this.setPageFromUrlParam();
    this.loadResults();
  }

  protected abstract itemsPerPage();

  setPageFromUrlParam() {
    const param = this.route.snapshot.queryParams["page"];
    param ? this.page = param: this.page = 1;
  }

  onPaginationChange(paginationObject: PaginationObject) {
    this.page = paginationObject.page;
    this.loadResults();
  }

  protected abstract getActiveAndNoticeFilter() : (
    {showActive?: boolean,
    showInactive?: boolean,
    checkedNoticeSeverityFilters?: string[];
    checkedNoticeTypeFilters?: NoticeTypeFilter[]});

  protected loadResults() {
    this.searchResults$ = undefined;
    const activeAndNoticeFilter = this.getActiveAndNoticeFilter();
    const filterState = {
      showActive: true, // default
      showInactive: false, // default
      checkedNoticeSeverityFilters: [], // default
      checkedNoticeTypeFilters: [], // default

      ...activeAndNoticeFilter, // overrides

      curriculumYearId: this.applicationState.curriculumYear.uuid,
      itemsPerPage: this.itemsPerPage(),
      searchTerm: this.searchTerm,
      page: this.page,
    };
    this.paginatedSearchResults(filterState);
  }

  protected abstract paginatedSearchResults(filterState);

  search(searchTerm: string) {
    if(searchTerm !== this._searchTerm){
      this._searchTerm = searchTerm;
      this.resetToFirstPage();
      this.loadResults();
    }
  }

  private resetToFirstPage(){
    this.page = 1;
  }

  get searchTerm() {
    if (this.hasSearchTerm()) {
      return this._searchTerm;
    }
    else {
      return '';
    }
  }

  hasSearchTerm() : boolean {
    return this._searchTerm !== undefined && this._searchTerm !== "";
  }

}
