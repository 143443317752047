import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {PendingChangesModalComponent} from "../components/pending-changes-modal/pending-changes-modal.component";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuardGuard implements CanDeactivate<ComponentCanDeactivate> {

  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {
  }

  canDeactivate(component: ComponentCanDeactivate,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const confirm = new Subject<boolean>();

    if (component.canDeactivate()) {
      return true;
    } else {
      this.modalRef = this.modalService.show(PendingChangesModalComponent, {ignoreBackdropClick: true, backdrop: false, keyboard: false});
      this.modalRef.content.confirm = confirm;
      return confirm.asObservable();
    }
  }
}
